.rootContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.linkContainer {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
    padding: 10px;
    border-color: 3px solid black;
    box-shadow: 0cqw 0px 5px 0px rgba(0, 0, 0, 0.75);
    background-color: lightgray;
    margin: 10px;
}