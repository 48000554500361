body {
  margin: 0;
  font-family: serif;
}

.App {
  text-align: center;
}

.success {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  background-color: #0766CD !important;
  text-align: center;
  color: white;
}

.error {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  background-color: red !important;
  text-align: center;
  color: white;
}