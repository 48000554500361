.mainContainer{
    display: flex;
    justify-content: center; 
}
.root{
    background-color: #9cd7e2;
    width: 100vw;
    height: 90vh;
}
.buttonContainer1{
    /* position: absolute; */
    /* top: 33vh; */
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.buttonContainer2{
    /* position: absolute; */
    /* top: 45vh; */
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
.bikeButton{
    width: 100px;
    height: 73px;
    background-color: #00BEE8;
    color: white;
    border: 1px solid black;
    border-radius: 10%;
    font-size: 23px;
    text-align: center;
    
}
.bikeButton > span{
    vertical-align:sub;
}
.bikeButton:hover{
    background-color: #0084BD;
    cursor: pointer;
}
.bottomButton{
    /* position: absolute; */
    /* bottom: 4vh; */
    /* background-color: #00BEE8; */
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    text-align: center;
    height: 40px;
    font-size: x-large;
    color: white;
}

.iconsGrid{
    /* position: absolute; */
    /* top: 60vh; */
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
}

#tvsIcon{
        left: 0px;
        width: 46px;
        background: url('images/icons.png') 0 0;
}   


.tvsIcon{
    /* left: 0px; */
    width: 80px;
    height: 40px;
    background: url('images/icons.png') 0px 0;
}   
.bajajIcon{
    /* left: 0px; */
    width: 80px;
    height: 40px;
    background: url('images/icons.png') 230px 0;
}   
.royalEnfieldIcon{
    /* left: 0px; */
    width: 80px;
    height: 40px;
    background: url('images/icons.png') 160px 0;
}   
.yamahaIcon{
    /* left: 0px; */
    width: 80px;
    height: 40px;
    background: url('images/icons.png') 80px 0;
}   
.hondaIcon{
    /* left: 0px; */
    width: 80px;
    height: 40px;
    background: url('images/icons.png') 0px -63px;
}   
.suzukiIcon{
    /* left: 0px; */
    width: 80px;
    height: 40px;
    background: url('images/icons.png') 230px -63px;
}   
.mahindraIcon{
    /* left: 0px; */
    width: 80px;
    height: 40px;
    background: url('images/icons.png') 160px -63px;
}   
.heroIcon{
    /* left: 0px; */
    width: 80px;
    height: 40px;
    background: url('images/icons.png') 80px -58px;
}   
.ktmIcon{
    /* left: 0px; */
    width: 80px;
    height: 40px;
    background: url('images/icons.png') 0px -120px;
}
   
.iconContainer{
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
    
}
.iconContainer:hover{
    background-color: #fff7f7;
}
.ktmIcon:hover{
    background: url('images/icons_hover.png') 0px -120px;
}
.tvsIcon:hover{
    
    background: url('images/icons_hover.png') 0px 0;
}   
.bajajIcon:hover{
    
    background: url('images/icons_hover.png') 230px 0;
}   
.royalEnfieldIcon:hover{
    background: url('images/icons_hover.png') 160px 0;
}   
.yamahaIcon:hover{
    background: url('images/icons_hover.png') 80px 0;
}   
.hondaIcon:hover{
    background: url('images/icons_hover.png') 0px -63px;
}   
.suzukiIcon:hover{
    background: url('images/icons_hover.png') 230px -63px;
}   
.mahindraIcon:hover{
    background: url('images/icons_hover.png') 160px -63px;
}   
.heroIcon:hover{
    background: url('images/icons_hover.png') 80px -58px;
}   

