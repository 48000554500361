.heading {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    background-color: #0766CD !important;
    text-align: center;
    color: white;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    height: 85vh;
}

.rootContainer {
    background-color: #87CEEB;
}

.serviceRootContainer {
    background-color: #87CEEB;
    /* height: 100vh; */
}

.customerDetailContainer {
    /* text-align: center; */
}

.customerDetailInnerDiv {
    border: 1px solid #0766CD;
    border-radius: 10px;
    background-color: white;
    width: 90%;
    margin-left: 5%;
}

.profilePicDiv {
    width: 30%;
    display: inline-block;
}

.customerDetailDiv {
    display: inline-block;
    vertical-align: top;
    margin-left: 5%;

}

.name {
    font-size: x-large;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
}

.locationIcon {
    padding-top: 10px;
    font-size: larger;
}

.starDisabled {
    /* font-size: 75px; */
    color: #D3D3D3;
    text-align: center;
}

.starContainer {
    text-align: center;


}

.starActive {
    color: #FFD700;
    display: inline-block;
}

.starInactive {
    color: #D3D3D3;
    display: inline-block;
}

.reviewText {
    background-color: white;
    width: 90%;
    border-radius: 10px;
}

.reviewContainer {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.btnContainer {
    text-align: center;
    padding: 1%;

}

.saveBtn {
    border-radius: 20px !important;
    width: 40%;
    box-shadow: 0px 7px #002D72 !important;
}

.success {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    background-color: #0766CD !important;
    text-align: center;
    color: white;
}

.error {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    background-color: red !important;
    text-align: center;
    color: white;
}

.successContainer {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.ddlContainer {
    padding: 1%;
    /* margin-top: 2% !important; */
    width: 75%;
    margin-left: 15%;
    margin-top: 2%;
    margin-bottom: 2%;


}

.customer {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.reviewText {
    background-color: white;
    width: 90%;
    border-radius: 10px;
    margin-left: 5%;
    margin-right: 5%;
    padding: 1%;
}

.starReview {
    display: inline-block;
    color: #FFD700;
}

.approveBtn {
    border-radius: 5px !important;
    box-shadow: 0px 3px #002D72 !important;
    background-color: lightgreen;
    text-transform: uppercase;
}

.rejectBtn {
    border-radius: 5px !important;
    box-shadow: 0px 3px #002D72 !important;
    background-color: lightgoldenrodyellow;
    text-transform: uppercase;
    margin-left: 10px;
}

.bikeServiceContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
}

.actionBtn {
    border-radius: 5px !important;
    box-shadow: 0px 3px #002D72 !important;
    background-color: lightgreen;
    text-transform: uppercase;
    margin-left: 10px;
    margin-top: 2%;
    height: 50px;
}

.header {
    background-color: #0766CD !important;
    color: white;
    text-align: center;
    padding: 1%;
    font-size: 1.5rem;
    font-weight: 600;
}

.aggregateBtn {
    border-radius: 5px !important;
    box-shadow: 0px 3px #002D72 !important;
    background-color: lightgreen;
    text-transform: uppercase;
    margin-left: 10px;
    margin-top: 2%;
    height: 50px;
}

.ratingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2%;
}

.workshopRating {
    text-align: center;
}