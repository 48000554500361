.servicesContainer {
    width: 80%;
    margin: 0 auto;
    padding: 40px;
    background-color: #f3f4f6;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    color: #333;
    text-align: center;
  }
  
  .title {
    font-size: 2.5rem;
    color: #003366;
    margin-bottom: 20px;
  }
  
  .intro {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #555;
  }
  
  .serviceHeading {
    font-size: 1.8rem;
    color: #004080;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .serviceDescription {
    font-size: 1.1rem;
    color: #444;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .contact {
    font-size: 1.2rem;
    margin-top: 30px;
  }
  
  .email {
    color: #003366;
    text-decoration: none;
    font-weight: bold;
  }
  
  .email:hover {
    text-decoration: underline;
    color: #00509e;
  }
  
  a {
    color: #007acc;
    transition: color 0.3s ease;
  }
  
  a:hover {
    color: #00509e;
  }
  