.component {
    width: 80%;
}

.componentContainer {
    padding: 1%;
    text-align: center;
}

.ddlContainer {
    padding: 1%;
    text-align: center;
}

.ddl {

    width: 80%;
    font-size: large !important;
    color: grey !important;

}

.errorMessage {
    color: red !important;
    display: inline-block !important;
    margin-left: 15%;
}

.header {
    color: #000;
    font-size: 20px;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 5px;
}

.success {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    background-color: #0766CD !important;
    text-align: center;
    color: white;
}

.error {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    background-color: red !important;
    text-align: center;
    color: white;
}

.passwordContainer {
    padding: 1%;
    justify-content: center;
    display: flex;
}