body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    color: #333;
}

.header {
    background-color: skyblue;
    color: #fff;
    padding: 1rem;
    text-align: center;
    height: 7vh;
    padding-bottom: 10px;
    padding-top: 5px;
}

.nav {
    margin-top: 1rem;
}

.nav a {
    color: #fff;
    margin: 0 1rem;
    text-decoration: none;
}

.hero {
    background: url('https://example.com/hero-image.jpg') no-repeat center center/cover;
    color: #fff;
    text-align: center;
    padding: 4rem 1rem;
}

.cta {
    display: inline-block;
    margin-top: 1rem;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.cta:hover {
    background-color: #555;
}

.services,
.bikes,
.testimonials,
.contact {
    padding: 4rem 1rem;
    text-align: center;
}

.service-cards,
.bike-cards,
.testimonial-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.service-card,
.bike-card,
.testimonial-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 2rem;
    margin: 1rem;
    flex: 1 1 calc(33% - 2rem);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.bike-card img {
    max-width: 100%;
    border-radius: 10px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-form label {
    margin-top: 1rem;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    max-width: 400px;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.contact-form button {
    margin-top: 1rem;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-form button:hover {
    background-color: #555;
}



.admin_nav {
    color: #fff;
}

.logo_img {
    width: 150px;
    height: 50px;
}

.content {

    /* padding-bottom: 10px; */
}

.welcome {
    color: white;
    font-size: medium;
    padding-right: 7px;
}

.logoContainer {
    text-align: left;
}