.loginPage {
    color: #333;
    top: 45vh;
    position: absolute;
}

.loginContainer {
    background-color: #00D0E9;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginForm label {
    margin-top: 1rem;
    width: 100%;
    text-align: left;
}

.loginForm input {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.loginForm button {
    margin-top: 1rem;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.loginForm button:hover {
    background-color: #555;
}

.error {
    color: red;
}

.container {
    position: relative;
    text-align: center;
    height: 100vh;
    background-color: #4B8DE6;
}

.heading {
    top: 5vh;
    position: absolute;
    display: flex;
    width: 100vw;
    flex-direction: column;
    color: #fff;
}

.heading1 {
    font-size: 20px;
    font-weight: 600;
}

.heading2 {
    font-size: 50px;
    font-weight: 600;
}

.bigImage {
    width: 45vh;
    height: 20vh;
}

.headingImageDiv {
    top: 17vh;
    width: 100vw;
    position: absolute;
}

.loginPageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.loginLinks{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    color:#0084BD;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
}
.linksContainer{
    width: 100%;
}
.passwordContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
}
