.component {
    text-align: center;
    /* width: 30%;
    margin-left: 30% !important; */
    margin-top: 2%;
}


.ddl {
    /* margin-left: 30% !important;
    width: 30% !important; */
    font-size: large !important;
    color: grey !important;
    /* margin-top: 2% !important; */
}

.scheduleDate {
    display: inline-block;
}

.scheduleTime {
    display: inline-block;
}

.saveButton {
    margin-top: 2% !important;
    padding-bottom: 10px !important;
}

.errorMessage {
    color: red !important;
    display: inline-block !important;
    margin-left: 15%;
}

.saveMessage {
    margin-left: 40%;
    margin-top: 2%;
}

.fieldHeader {
    display: inline-block;
    font-size: large;
    width: 13%;
    font-weight: 600;
}

.field {
    display: inline-block;
    font-size: large;
    width: 13%;
    font-weight: 600;
    padding-top: 1%;
    padding-bottom: 1%;
}

.serviceDate {
    display: inline-block;
    font-size: large;
    width: 17% !important;
    font-weight: 600;
}

.serviceDateHeader {
    width: 17% !important;
}

.fieldRow {
    padding-top: 1%;
    padding-bottom: 1%;
    background-color: bisque;
}

.headerRow {
    padding-top: 1%;
    padding-bottom: 1%;
    background-color: cornsilk;
}

.header {
    font-size: larger;
    font-weight: bolder;
    text-align: center;
}

.bookingKey {
    display: inline-block;
    font-size: large;
    width: 45%;
    font-weight: 600;
    /* margin-left: 20%; */
    padding-top: 2%;
    padding-bottom: 2%;
    text-align: left;
    vertical-align: top;
}

.bookingValue {
    display: inline-block;
    font-size: large;
    width: 40%;
    font-weight: 600;
    text-align: left;

}

.bookingRow {
    padding-top: 1%;
    padding-bottom: 1%;
    text-align: center;
}

.bookingDeailContainer {
    text-align: center;

}

.workerddl {
    width: 100% !important;
    font-size: large !important;
    color: grey !important;
    margin-top: 5% !important;
}

.carWashContainerDiv {
    padding-bottom: 20px;
}

.propContainer {
    display: flex;
    flex-direction: column;

}

.header {
    color: #000;
    font-size: 20px;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 5px;
}

.success {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    background-color: #0766CD !important;
    text-align: center;
    color: white;
}

.error {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    background-color: red !important;
    text-align: center;
    color: white;
}