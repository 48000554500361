.component {
    /* text-align: center; */
    width: 65%;
}

.rootContainer {
    background-color: #4B8DE6;
    color: #76FE80;
    padding-bottom: 20px;;
}

.componentContainer {
    text-align: center;
    text-transform: uppercase;

}

.ddlContainer {
    padding: 1%;
    /* margin-top: 2% !important; */
    width: 75%;
    margin-left: 15%;
    margin-top: 2%;
    margin-bottom: 2%;


}

.plainTextBox {
    background-color: #ECF0F2;
}

.ddl {

    background-color: #ECF0F2;
    border-radius: 10px;
}

.scheduleDate {
    display: inline-block;
    /* margin-left: 30%; */
}

.scheduleTime {
    display: inline-block;
}

.saveButton {
    margin-left: 40% !important;
    margin-top: 2% !important;
    border-radius: 15px;
}

.errorMessage {
    color: red !important;
}

.saveMessage {
    color: white !important;
}

.fieldHeader {
    display: inline-block;
    font-size: large;
    width: 11%;
    font-weight: 600;
}

.field {
    display: inline-block;
    font-size: large;
    width: 11%;
    font-weight: 600;
    padding-top: 1%;
    padding-bottom: 1%;
}

.serviceDate {
    display: inline-block;
    font-size: large;
    width: 17% !important;
    font-weight: 600;
}

.serviceDateHeader {
    width: 17% !important;
}

.fieldRow {
    padding-top: 1%;
    padding-bottom: 1%;
    background-color: bisque;
}

.headerRow {
    padding-top: 1%;
    padding-bottom: 1%;
    background-color: cornsilk;
}

.header {
    color: #FFF;
    font-size: 20px;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 5px;
}

.bookingKey {
    display: inline-block;
    font-size: large;
    width: 40%;
    font-weight: 600;
    /* margin-left: 30%; */
    text-align: left;
}

.bookingValue {
    display: inline-block;
    font-size: large;
    width: 40%;
    font-weight: 600;
    text-align: left;

}

.bookingRow {
    padding-top: 1%;
    padding-bottom: 1%;
}

.bookingDeailContainer {
    text-align: center;

}

.iconDiv {
    display: inline-block;
}

.textDiv {
    display: inline-block;
    vertical-align: top;
    /* margin-left: 5%; */
    background-color: #ECF0F2;
    border-radius: 10px;
    width: 71%;
}

.txtBox {
    width: 98%;
    /* padding: 1%; */
}

.icon {
    margin-top: 15px;
}

.dualComponentContainer {
    text-align: center;
    margin-left: 3vw;
    margin-top: 2%;
    margin-bottom: 2%;
}

.dateTime {
    margin-top: 2%;
    margin-bottom: 2%;
    background-color: #ECF0F2;
    border-radius: 10px;
}

.success {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    background-color: #0766CD !important;
    text-align: center;
    color: white;
}

.error {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    background-color: red !important;
    text-align: center;
    color: white;
}
.fieldName {
    display: inline-block;
    width: 35%;
    font-size: large;
    padding-top: 5px;
    padding-bottom: 5px;
}

.fieldValue {
    display: inline-block;
    width: 64%;
    font-size: large;
    padding-top: 5px;
    padding-bottom: 5px;
    /* font-weight: bold; */
    text-align: center;
}
.serviceItem{
    border: 2px solid black;
    border-radius: 5px;
    padding: 2px;
    margin: 5px;
    background-color: ivory;
}
.serviceButtonContainer{
    margin: 2%;
    text-align: center;
    display: flex;
    justify-content: space-evenly

}
.itemList{
    max-width: 600px;
    margin-top: 2%;
}
.itemsRoot{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.searchBox{
    margin-top: 2%;
    margin-bottom: 2%;
    display: flex;
    justify-content: center;
    background-color: #FFF;
}
.searchIcon{
    margin-left: -32px;
    margin-top: 16px;
}