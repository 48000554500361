.footer {
    display: flex;
    align-content: space-between;
    /* flex-wrap: wrap; */
    /* justify-content: center; */
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: skyblue;
    color: white;
    text-align: center;
    height: 20px;
}