.container {
    position: relative;
    text-align: center;
    height: 100vh;
    background-color: #00BEE8;
}

.bigImage {
    width: 45vh;
    height: 20vh;
    /* object-fit: cover; */
    /* filter: brightness(50%); */
}


.loginRegister {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
}

.keyIcon {
    margin-right: 10px;
    color: bisque;
}

.loginLink {
    color: #fff;
    text-decoration: none;
}


.scrollableLogosContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    overflow-x: auto;
    padding: 10px 0;
    background-color: rgba(0, 0, 0, 0.7);
}

.logo {
    height: 50px;
    width: auto;
    margin: 0 20px;
    transition: transform 0.3s;
}

.logo:hover {
    transform: scale(1.1);
}

.containerDiv {
    top: 40vh;
    width: 100vw;
    position: absolute;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.button {
    background-color: #FAD3A8 !important;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
    width: 250px;
    border-radius: 15px !important;
    box-shadow: 5px 10px #FE9540;
    color: #0665CD !important;
    font-weight: 600 !important;
}

.button:hover {
    background-color: #45a049 !important;
}

.buttonDiv {
    padding: 10px;
}

.headingImageDiv {
    top: 17vh;
    width: 100vw;
    position: absolute;
}

.heading {
    top: 5vh;
    position: absolute;
    display: flex;
    width: 100vw;
    flex-direction: column;
    color: #fff;
}

.heading1 {
    font-size: 20px;
    font-weight: 600;
}

.heading2 {
    font-size: 50px;
    font-weight: 600;
}